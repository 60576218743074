import { Dropdown as BootstrapDropdown } from "react-bootstrap";
import {
  createContext,
  forwardRef,
  ReactNode,
  RefObject,
  useContext,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { BasicElementProps } from "h11-client-component-lib";
import { createPortal } from "react-dom";
import { clsx } from "clsx";
import "./BetterDropdown.scss";

export interface InputHandle {
  focus: () => void;
}

// TODO composite component
export interface BetterDropdownProps extends BasicElementProps {
  open: boolean;
  onChange: (open: boolean) => void;
  control: (props: {
    currentOpen: boolean;
    onOpenChange: (open: boolean) => void;
    ref: RefObject<InputHandle>;
  }) => ReactNode;

  // TODO přejmenovat menu na popup nebo popupcontent
  menu: ReactNode;
}

export interface BetterDropdownHandle {
  focusInput: () => void;
}

export const BetterDropdownContext = createContext<{
  isOpen: boolean;
  changeOpen: (open: boolean) => void;
} | null>(null);

export const BetterDropdown = forwardRef<
  BetterDropdownHandle,
  BetterDropdownProps
>(({ open, onChange, control, menu, id, tabIndex, className, style }, ref) => {
  useImperativeHandle(ref, () => ({
    focusInput: () => {
      controlRef.current?.focus();
    },
  }));
  const controlRef = useRef<InputHandle>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  return (
    <BetterDropdownContext.Provider
      value={{ isOpen: open, changeOpen: onChange }}>
      <BootstrapDropdown
        id={id}
        tabIndex={tabIndex}
        ref={dropdownRef}
        style={style}
        className={clsx(
          "BaseSelectComponent",
          "Dropdown",
          "BetterDropdown",
          className,
        )}
        show={open}
        onBlur={e => {
          if (
            !dropdownRef.current?.contains(e.relatedTarget) &&
            !menuRef.current?.contains(e.relatedTarget)
          ) {
            onChange(false);
          }
        }}>
        <BootstrapDropdown.Toggle as="div">
          {control({
            ref: controlRef,
            currentOpen: open,
            onOpenChange: onChange,
          })}
        </BootstrapDropdown.Toggle>
        {open &&
          createPortal(
            <div
              className={clsx(
                className,
                // TODO vyhodit BaseSelectComponent a dropdown a udělat vlastní
                "BaseSelectComponent",
                "dropdown",
                "backdrop",
              )}>
              <BootstrapDropdown.Menu
                ref={menuRef}
                tabIndex={-1 /*For element to be focusable*/}
                /*popperConfig={{
                  modifiers: [
                    {
                      name: "updateState",
                      enabled: true,
                      phase: "afterWrite",
                      fn: ({ state }) => {
                        const flipped = state.placement.startsWith("top");
                        setMenuFlipped(flipped);
                      },
                    },
                  ],
                }}*/
                onKeyDown={e => {
                  switch (e.key) {
                    case "ArrowUp":
                      if (
                        e.target instanceof HTMLElement &&
                        e.target.classList.contains("first")
                      ) {
                        e.preventDefault();
                        e.stopPropagation();
                        controlRef.current?.focus();
                      }
                      break;
                    case "Escape":
                      e.preventDefault();
                      e.stopPropagation();
                      controlRef.current?.focus();
                      onChange(false);
                      break;
                  }
                }}>
                {menu}
              </BootstrapDropdown.Menu>
            </div>,
            document.body,
          )}
      </BootstrapDropdown>
    </BetterDropdownContext.Provider>
  );
});
BetterDropdown.displayName = "BetterDropdown";

export function BetterDropdownUncontrolled(
  props: Omit<BetterDropdownProps, "open" | "onChange">,
) {
  const [open, setOpen] = useState(false);
  return <BetterDropdown open={open} onChange={setOpen} {...props} />;
}

export function useBetterDropdown() {
  const context = useContext(BetterDropdownContext);
  if (!context) {
    throw new Error("useBetterDropdown must be used within a BetterDropdown");
  }
  return context;
}
