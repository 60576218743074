import { ReservationFormData, RoomFormData } from "../../reservationData";
import { UseFieldArrayReturn } from "react-hook-form";
import { forwardRef, useCallback, useMemo } from "react";
import { termsAndRoomTypesSectionHeadersWidths } from "../TermsAndRoomTypesSection";
import { NumberField, useTranslation } from "h11-client-component-lib";
import { useReservationContext } from "../../ReservationFormContext";
import { AutocompleteField } from "../../../to_lib/dropdown/AutocompleteField";

// As array of objects, because autocomplete requires it for highlighting
const roomsCountOptions = [...Array(199)].map((_, i) => ({
  num: i + 1,
  label: (i + 1).toString(),
}));

interface RoomsCountFieldProps {
  value: number | undefined;
  onChange: (value: number | undefined) => void;
}

const RoomsCountField = forwardRef<HTMLElement, RoomsCountFieldProps>(
  ({ value, onChange }, ref) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 20,
        }}>
        <NumberField
          ref={ref}
          value={value}
          onChange={onChange}
          variant="buttoned"
          fieldWidth={40}
          style={{ textAlign: "center" }}
        />
      </div>
    );
  },
);
RoomsCountField.displayName = "RoomsCountField";

export const RoomsCountSelectField = ({
  roomsFieldArray,
}: {
  roomsFieldArray: UseFieldArrayReturn<ReservationFormData, "groups.0.rooms">;
}) => {
  const { t } = useTranslation();
  const { createDefaultRoom } = useReservationContext();

  const roomsCount = useMemo(
    () => roomsFieldArray.fields.length ?? 0,
    [roomsFieldArray.fields],
  );

  const handleChange = useCallback(
    (newCount: number) => {
      // Modify rooms to match the new count
      const newRooms: RoomFormData[] = [...roomsFieldArray.fields];
      if (newCount !== roomsCount) {
        if (newCount > roomsCount) {
          for (let i = roomsCount; i < newCount; i++) {
            newRooms.push(createDefaultRoom());
          }
        } else {
          // FIXME sofistikovanější odebírání hostů a pokojů - kontrola, že ty co odebírám nejsou vyplněné, jinak varování, že to má udělat ručně
          newRooms.splice(newCount);
        }
        roomsFieldArray.replace(newRooms);
      }
    },
    [roomsFieldArray.fields, roomsCount, createDefaultRoom],
  );

  return (
    <AutocompleteField
      label={t("rooms_count")}
      items={roomsCountOptions}
      getId={i => i.num}
      renderValue={i => i.labelHighlighted}
      renderTextValue={i => i.label}
      onChange={i => handleChange(i?.num ?? 0)}
      value={roomsCountOptions.find(i => i.num === roomsCount)}
      filterFields={["label"]}
      compareMode="startsWith"
      commitMode="on-close"
      numberOnly
      TextFieldProps={{
        fieldWidth: "autosize",
        inputStyle: { minWidth: termsAndRoomTypesSectionHeadersWidths[2] - 38 },
      }}
    />
  );
};
