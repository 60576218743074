import {
  Button,
  Panel,
  useTranslation,
  VerticalStack,
} from "h11-client-component-lib";
import { PlusCircle } from "react-bootstrap-icons";
import { RoomGroupPanel } from "./room_group/RoomGroupPanel";
import { UseFieldArrayReturn } from "react-hook-form";
import { ReservationFormData } from "../reservationData";

// Fixed widths for use in header and child group components
export const termsAndRoomTypesSectionHeadersWidths = [
  180, 120, 64, 64, 64, 80, 48,
] as const;

export const TermsAndRoomTypesSection = ({
  groups,
  onAddRoomGroup,
}: {
  groups: UseFieldArrayReturn<ReservationFormData, "groups">;
  onAddRoomGroup: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Panel
      className="TermsAndRoomTypesSection"
      label={t("terms_and_room_types")}
      variant="naked"
      controls={
        <Button
          variant="secondary"
          onClick={onAddRoomGroup}
          icon={<PlusCircle />}>
          {t("add_reservation_row")}
        </Button>
      }>
      <VerticalStack fullWidth>
        {groups.fields.map((g, index) => (
          <RoomGroupPanel
            key={g.id}
            groupIndex={index}
            onDelete={() => groups.remove(index)}
          />
        ))}
      </VerticalStack>
    </Panel>
  );
};
