import {
  forwardRef,
  ReactNode,
  RefObject,
  useImperativeHandle,
  useRef,
} from "react";
import { Dropdown as BootstrapDropdown } from "react-bootstrap";
import { BasicElementProps, useTranslation } from "h11-client-component-lib";
import { useBetterDropdown } from "./BetterDropdown";
import "./ItemsMenu.scss";
import { clsx } from "clsx";

export interface ItemsProps<ItemType> {
  getId: (v: ItemType) => string | number;
  items: readonly ItemType[];
  renderValue: (value: ItemType) => ReactNode;
}

export interface ItemsMenuProps<ItemType> extends ItemsProps<ItemType> {
  selectedItemId?: string | number;
  onItemSelected: (value: ItemType) => void;
  menuRef?: RefObject<ItemsMenuHandle>;
}

export interface ItemsMenuHandle {
  focus: () => void;
  scrollToSelected: () => void;
}

export const ItemsMenu = <ItemType,>({
  items,
  renderValue,
  getId,
  onItemSelected,
  selectedItemId,
  menuRef, // menuRef instead of ref, because of the generic params
}: ItemsMenuProps<ItemType>) => {
  const { t } = useTranslation();
  const firstItemRef = useRef<HTMLAnchorElement>(null);
  const componentItemsMenuRef = useRef<HTMLDivElement>(null);
  useImperativeHandle(menuRef, () => ({
    focus: () => {
      firstItemRef.current?.focus();
    },
    scrollToSelected: () => {
      componentItemsMenuRef.current
        ?.querySelector(".selected")
        ?.scrollIntoView({ block: "start" });
    },
  }));
  return (
    <ComponentItemsMenu ref={componentItemsMenuRef}>
      {items.length > 0 ? (
        items.map((i, index) => (
          <ComponentItem
            key={getId(i)}
            selected={getId(i) === selectedItemId}
            ref={index === 0 ? firstItemRef : undefined}
            className={index === 0 ? "first" : undefined}
            onClick={() => {
              onItemSelected(i);
            }}>
            {renderValue(i)}
          </ComponentItem>
        ))
      ) : (
        <ComponentItem className="no-items" disabled>
          {t("no_items")}
        </ComponentItem>
      )}
    </ComponentItemsMenu>
  );
};

export interface ComponentItemsMenu {
  children: ReactNode;
}

export const ComponentItemsMenu = forwardRef<
  HTMLDivElement,
  ComponentItemsMenu
>(({ children }, ref) => (
  <div className="ItemsMenu" ref={ref}>
    <div className="scroller">{children}</div>
  </div>
));
ComponentItemsMenu.displayName = "ComponentItemsMenu";

export interface ComponentItemProps extends BasicElementProps {
  onClick?: () => void;
  children: ReactNode;
  disabled?: boolean;
  selected?: boolean;
}

export const ComponentItem = forwardRef<HTMLAnchorElement, ComponentItemProps>(
  ({ onClick, children, disabled, selected, className, ...restProps }, ref) => {
    const betterDropdown = useBetterDropdown();

    return (
      <BootstrapDropdown.Item
        {...restProps}
        className={clsx("ComponentItem", className, { selected })}
        ref={ref}
        disabled={disabled}
        onClick={
          onClick
            ? () => {
                betterDropdown.changeOpen(false);
                onClick();
              }
            : undefined
        }>
        <div className="content">{children}</div>
      </BootstrapDropdown.Item>
    );
  },
);
ComponentItem.displayName = "ComponentItem";
