import { useFormikContext } from "formik";
import { UserFormData } from "../userData";
import {
  Button,
  FlowLayout,
  FieldAlign,
  FormGrid,
  FormikDateField,
  FormikSelectField,
  FormikTextField,
  languages,
  Switch,
  useAppContext,
} from "h11-client-component-lib";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useMutation } from "react-relay";
import { UserResetPasswordMutation } from "@relay-generated/UserResetPasswordMutation.graphql";
import { CopyPromptDialog } from "@shared/ui/copyPrompt/CopyPromptDialog";
import { userResetPasswordMutation } from "./graphql/UserResetPasswordMutation";

export function UserAccountForm() {
  const { t } = useTranslation();
  const appContext = useAppContext();
  const formik = useFormikContext<UserFormData>();
  const isNew = !formik.values.userUid;

  const [passwordResetUrl, setPasswordResetUrl] = useState<string>();
  const [passwordResetDialogOpen, setPasswordResetDialogOpen] = useState(false);

  const [resetUserPasswordCommit] = useMutation<UserResetPasswordMutation>(
    userResetPasswordMutation,
  );

  function resetPassword() {
    if (formik.values.userUid) {
      resetUserPasswordCommit({
        variables: { id: formik.values.userUid },
        onCompleted: ({ resetUserPassword: { url } }) => {
          setPasswordResetUrl(url);
          setPasswordResetDialogOpen(true);
        },
      });
    }
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 50 }}>
      <FormGrid gridTemplateColumns="repeat(4, minmax(100px, 1fr))">
        <FormikTextField label={t("user_name")} field="userName" />
        {isNew && (
          <FormikTextField
            label={t("password")}
            field="password"
            helperText={t("password_hint")}
          />
        )}
        <FormikDateField label={t("valid_to")} field="validTo" />
        <FieldAlign>
          <Switch
            id="chain-user"
            label={t("chain_user")}
            checked={formik.values["chainUser"] ?? false}
            onChange={checked => {
              const doIt = () => formik.setFieldValue("chainUser", checked);
              if (checked) {
                doIt();
              } else {
                appContext
                  .confirm(t("turn_off_chain_user_question"))
                  .then(confirmed => {
                    if (confirmed) {
                      doIt();
                    }
                  });
              }
            }}
          />
        </FieldAlign>
        <FormikTextField
          label={t("name")}
          field="firstName"
          style={{ gridColumn: 1 }}
        />
        <FormikTextField label={t("surname")} field="lastName" />
        <FormikTextField label={t("code")} field="code" />
        <FormikTextField
          label={t("primary_phone")}
          field="tel"
          style={{ gridColumn: 1 }}
        />
        <FormikTextField label={t("primary_email")} field="email" />
        <FormikSelectField
          label={t("language")}
          field="lang"
          items={languages}
          itemIdExtractor={l => l}
          valueToString={l => l}
        />
        <FormikTextField
          label={t("note")}
          field="note"
          multiline={3}
          style={{ gridColumn: "1 / 3" }}
        />
      </FormGrid>
      {!isNew && (
        <FlowLayout variant="compact2">
          <Button variant="secondary" onClick={() => resetPassword()}>
            {t("generate_link_for_password_reset")}
          </Button>
        </FlowLayout>
      )}
      {passwordResetUrl && (
        <CopyPromptDialog
          title={t("password_reset")}
          fieldLabel={t("url")}
          open={passwordResetDialogOpen}
          text={passwordResetUrl}
          onClose={() => setPasswordResetDialogOpen(false)}
        />
      )}
    </div>
  );
}
