import { Controller, Path, useFormContext } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { AutocompleteField, AutocompleteFieldProps } from "./AutocompleteField";

export type FormSelectFieldProps<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ItemType extends object,
  TFieldValues extends FieldValues,
> = Omit<AutocompleteFieldProps<ItemType>, "value" | "name" | "onChange"> & {
  field: Path<TFieldValues>;
};

export function FormAutocompleteField<
  ItemType extends object,
  TFieldValues extends FieldValues,
>({ field, ...props }: FormSelectFieldProps<ItemType, TFieldValues>) {
  const { control } = useFormContext<TFieldValues>();

  return (
    <Controller
      name={field}
      control={control}
      render={({ field: fieldObj }) => (
        <AutocompleteField
          {...props}
          value={
            props.items.find(i => i && props.getId(i) === fieldObj.value) ??
            undefined
          }
          onChange={value =>
            fieldObj.onChange(value ? props.getId(value) : undefined)
          }
          // FIXME errors and mandatory
        />
      )}
    />
  );
}
