import { ReservationFormData, RoomFormData } from "../../reservationData";
import { UseFieldArrayReturn } from "react-hook-form";
import { useCallback, useMemo } from "react";
import { termsAndRoomTypesSectionHeadersWidths } from "../TermsAndRoomTypesSection";
import { determineGuestChildCategory } from "../../reservationUtils";
import { useReservationContext } from "../../ReservationFormContext";
import { AutocompleteField } from "../../../to_lib/dropdown/AutocompleteField";
import { useTranslation } from "h11-client-component-lib";

// As array of objects, because autocomplete requires it for highlighting
const guestsCountOptions = [...Array(999)].map((_, i) => ({
  num: i + 1,
  label: (i + 1).toString(),
}));

export const AdultsCountField = ({
  rooms,
  roomsFieldArray,
}: {
  rooms: RoomFormData[];
  roomsFieldArray: UseFieldArrayReturn<ReservationFormData, "groups.0.rooms">;
}) => {
  const { t } = useTranslation();
  const reservationContext = useReservationContext();

  const adultsCount = useMemo(() => {
    return rooms.reduce((acc, room) => {
      room.guests.forEach(g => {
        // adults only
        if (determineGuestChildCategory(g) === null) {
          acc += 1;
        }
      });
      return acc;
    }, 0);
  }, [rooms]);

  const handleChange = useCallback(
    (newCount: number) => {
      const count = adultsCount;
      if (newCount !== count) {
        // TODO logika přidání / odebírání hostů, musí být sofistikovanější
        // Modify rooms to match the new adults count
        const newRooms: RoomFormData[] = [...rooms];
        if (newCount > count) {
          // Add guests
          for (let i = count; i < newCount; i++) {
            newRooms[0]?.guests.push(
              reservationContext.createDefaultGuest(null),
            );
          }
        } else {
          let guestsToRemove = count - newCount;
          for (let i = newRooms.length - 1; i >= 0; i--) {
            const room = newRooms[i];
            const newGuests = [...room.guests];
            for (let c = newGuests.length - 1; c >= 0; c--) {
              if (determineGuestChildCategory(newGuests[c]) === null) {
                newGuests.splice(c, 1);
                --guestsToRemove;
              }
              if (guestsToRemove <= 0) {
                break;
              }
            }

            if (newGuests.length > 0) {
              newRooms[i] = { ...room, guests: newGuests };
            } else {
              newRooms.splice(i, 1);
            }

            if (guestsToRemove <= 0) {
              break;
            }
          }
        }
        roomsFieldArray.replace(newRooms);
      }
    },
    [adultsCount, reservationContext, rooms, roomsFieldArray],
  );

  return (
    <AutocompleteField
      label={t("adults_count")}
      items={guestsCountOptions}
      getId={i => i.num}
      renderValue={i => i.labelHighlighted}
      renderTextValue={i => i.label}
      onChange={i => handleChange(i?.num ?? 0)}
      value={guestsCountOptions.find(i => i.num === adultsCount)}
      filterFields={["label"]}
      compareMode="startsWith"
      commitMode="on-close"
      numberOnly
      TextFieldProps={{
        fieldWidth: "autosize",
        inputStyle: { minWidth: termsAndRoomTypesSectionHeadersWidths[3] - 38 },
      }}
    />
  );
};
