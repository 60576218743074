import {
  Divider,
  FlowGroup,
  FlowLayout,
  FieldAlign,
  FormGrid,
  FormikNumberField,
  FormikSelectField,
  FormikSwitch,
  FormikTextField,
  FormSection,
} from "h11-client-component-lib";
import { useTranslation } from "react-i18next";
import { PriceListTable } from "./formParts/PriceListTable";
import { InvoiceAddressesTable } from "./formParts/InvoiceAddressesTable";
import * as React from "react";
import { CSSProperties } from "react";

const preferredWidth = (width: number): CSSProperties => ({
  flexBasis: width,
  minWidth: width,
  flexGrow: 1,
});

export function ReservationSourceForm() {
  const { t } = useTranslation();

  // FIXME zobrazení datumů sahá na API, proč?

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}>
      <FormGrid style={{ flex: "1", minHeight: 0 }}>
        <FlowLayout>
          <FormikTextField label={t("source_code")} field="name" />
          <FormikTextField
            label={t("source_name")}
            field="name"
            style={preferredWidth(460)}
            helperText={t("input_company_name_and_select_from_list")}
          />
          <FormikTextField label={t("company_id_number")} field="dummy" />
          <FormikTextField label={t("tax_id_number")} field="dummy" />
          <FormikTextField
            label={t("company_id_number_for_vat")}
            field="dummy"
          />
        </FlowLayout>
        <FlowLayout>
          <FormikTextField
            label={t("title")}
            field="dummy"
            style={{ flexBasis: 70 }}
          />
          <FormikTextField label={t("name")} field="dummy" />
          <FormikTextField label={t("surname")} field="name" />
          <FormikTextField
            label={t("title")}
            field="dummy"
            style={{ flexBasis: 70 }}
          />
        </FlowLayout>
        <FlowLayout>
          <FormikTextField
            label={t("street_or_municipality_part")}
            field="dummy"
            style={preferredWidth(460)}
          />
          <FormikTextField label={t("street_number")} field="dummy" />
          <FormikTextField
            label={t("city")}
            field="dummy"
            style={preferredWidth(300)}
          />
          <FormikTextField label={t("postal_code")} field="dummy" />
          <FormikTextField label={t("country")} field="dummy" />
        </FlowLayout>
        <FlowLayout>
          <FormikTextField
            label={t("email")}
            field="dummy"
            style={preferredWidth(200)}
          />
          <FormikTextField
            label={t("phone")}
            field="dummy"
            style={preferredWidth(200)}
          />
          <FormikTextField
            label={t("bank_account")}
            field="dummy"
            style={preferredWidth(200)}
          />
          <FormikTextField label={t("payment_method")} field="dummy" />
        </FlowLayout>
        <FlowLayout>
          <FormikTextField label={t("guest_code")} field="dummy" />
          <FormikTextField label={t("sales_code")} field="dummy" />
          <FormikTextField
            label={t("for_confirmation_use")}
            field="dummy"
            style={preferredWidth(200)}
          />
          <FormikTextField label={t("iata")} field="dummy" />
          <FormikTextField label={t("source_type")} field="dummy" />
          <FormikTextField label={t("color")} field="dummy" />
          <FieldAlign>
            <FormikSwitch
              id="inactive_switch"
              label={t("inactive")}
              field="dummy"
            />
          </FieldAlign>
        </FlowLayout>
        <Divider />
        <FlowLayout>
          <FormSection title={t("price_lists")} style={{ flex: "1" }}>
            <PriceListTable />
          </FormSection>
          <FormSection title={t("allocation_release")} style={{ flex: "0" }}>
            <FlowGroup>
              <FormikNumberField
                label={t("day")}
                style={{ width: 60 }}
                field="allocation_release_day"
              />
              <FormikNumberField
                label={t("month")}
                style={{ width: 60 }}
                field="allocation_release_month"
              />
            </FlowGroup>
          </FormSection>
          <FormSection title={t("commission")} style={{ flex: "0" }}>
            <FlowLayout variant="compact2" style={{ rowGap: 20 }}>
              <FlowGroup>
                <FormikNumberField
                  label={t("value_in_percent")}
                  style={{ width: 120 }}
                  field="value_in_percent"
                />
                <FormikSelectField
                  label={t("currency")}
                  field="currency"
                  itemIdExtractor={v => v.id}
                  items={[
                    {
                      id: "CZK",
                      label: "CZK",
                    },
                    {
                      id: "EUR",
                      label: "EUR",
                    },
                  ]}
                  valueToString={value => value.label}
                  style={{ width: 90 }}
                />
              </FlowGroup>
              <FormikSwitch
                id="calculation_with_vat"
                label={t("calculation_with_vat")}
                field="calculation_with_vat"
              />
            </FlowLayout>
          </FormSection>
          <FormSection title={t("invoice_addresses")} style={{ flex: "2" }}>
            <InvoiceAddressesTable />
          </FormSection>
        </FlowLayout>
      </FormGrid>
    </div>
  );
}
