import { ItemsMenu, ItemsMenuHandle } from "./ItemsMenu";
import { ReactNode, useEffect, useRef, useState } from "react";
import { BetterDropdown, BetterDropdownHandle } from "./BetterDropdown";
import { InputFieldDropdownToggle } from "./InputFieldDropdownToggle";
import {
  BasicElementProps,
  FilterAndHighlightCompareMode,
  Highlighted,
  StringKeyOfValueType,
  TextFieldProps,
  useHighlightAndFilter,
} from "h11-client-component-lib";
import "./AutocompleteField.scss";
import { clsx } from "clsx";

export interface AutocompleteFieldProps<ItemType extends object>
  extends BasicElementProps {
  label?: string;
  items: readonly ItemType[];
  getId: (v: ItemType) => string | number;
  renderValue: (
    value: ItemType &
      Highlighted<
        ItemType,
        StringKeyOfValueType<ItemType, string | undefined | null>
      >,
  ) => ReactNode;
  renderTextValue: (item: ItemType) => string;
  onChange: (item: ItemType | undefined) => void;
  value: ItemType | undefined;
  filterFields: StringKeyOfValueType<ItemType, string | undefined | null>[];
  TextFieldProps?: Partial<TextFieldProps>;
  onDropdownClosed?: () => void;
  compareMode?: FilterAndHighlightCompareMode;

  // If on-match the onChange is called right when the user input matches any value. On-close, when the dropdown closes
  commitMode?: "on-match" | "on-close";
  numberOnly?: boolean;
}

export const AutocompleteField = <ItemType extends object>({
  label,
  items, // Items separated from restProps, because of the filter
  value,
  onChange,
  renderTextValue,
  filterFields,
  TextFieldProps,
  getId,
  renderValue,
  className,
  onDropdownClosed,
  numberOnly,
  compareMode = "includes",
  commitMode = "on-match",
  ...restProps
}: AutocompleteFieldProps<ItemType>) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const dropdownRef = useRef<BetterDropdownHandle>(null);
  const menuRef = useRef<ItemsMenuHandle>(null);

  // This serves the purpose so onDropdownClosed is called only when the dropdown is really closed and not when onDropdownClosed changes
  const oldOpen = useRef(open);

  const [filterAndHighlight, setFilterAndHighlight] = useState(false);

  useEffect(() => {
    if (value) {
      setInputValue(renderTextValue(value));
    }
  }, [value]);

  // TODO potom udělat nějaký autocomplete s tabulkou

  const highlightedItems = useHighlightAndFilter({
    items,
    search: inputValue,
    fields: filterFields,
    filter: filterAndHighlight,

    // Explicitly turned off highlighting for numbers only
    highlight: filterAndHighlight && !numberOnly,

    compareMode,
  });

  // This is used to store the value when commitMode is on-close
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  useEffect(() => {
    if (oldOpen.current && !open) {
      if (commitMode === "on-close") {
        onChange(internalValue);
      }
      if (onDropdownClosed) {
        onDropdownClosed();
      }
    }
    oldOpen.current = open;
  }, [open, onDropdownClosed, onChange]);

  useEffect(() => {
    if (open) {
      menuRef.current?.scrollToSelected();
    }
  }, [open]);

  const findExactMatch = (highlightedItems: ItemType[]) => {
    return highlightedItems.find(i => renderTextValue(i) === inputValue);
  };
  const handleChange = (newValue: ItemType | undefined) => {
    if (commitMode === "on-match") {
      onChange(newValue);
    } else {
      setInternalValue(newValue);
    }
  };

  useEffect(() => {
    const exactMatchHighlighted = findExactMatch(highlightedItems);
    if (exactMatchHighlighted) {
      handleChange(exactMatchHighlighted);
    }
  }, [highlightedItems]);

  useEffect(() => {
    menuRef.current?.scrollToSelected();
  }, [internalValue]);

  return (
    <BetterDropdown
      {...restProps}
      className={clsx("AutocompleteField", className)}
      ref={dropdownRef}
      open={open}
      onChange={newOpen => {
        if (!open && newOpen) {
          setFilterAndHighlight(false);
        }
        setOpen(newOpen);
      }}
      control={({ ref }) => (
        <InputFieldDropdownToggle
          label={label}
          ref={ref}
          value={inputValue}
          selectAllOnFocus
          numberOnly={numberOnly}
          onEnter={e => {
            e.preventDefault();
            const exactMatchHighlighted = findExactMatch(highlightedItems);
            if (exactMatchHighlighted) {
              handleChange(exactMatchHighlighted);
              setOpen(false);
            } else if (highlightedItems.length === 1) {
              handleChange(highlightedItems[0]);
              setOpen(false);
            } else if (highlightedItems.length > 0) {
              setOpen(true);
              menuRef.current?.focus();
            }
          }}
          onChange={a => {
            setFilterAndHighlight(true);
            setInputValue(a);
            if (value && renderTextValue(value) !== a) {
              handleChange(undefined);
            }
            setOpen(true);
          }}
          TextFieldProps={TextFieldProps}
        />
      )}
      menu={
        <ItemsMenu
          menuRef={menuRef}
          getId={getId}
          renderValue={i => renderValue(i)}
          items={highlightedItems}
          selectedItemId={internalValue ? getId(internalValue) : undefined}
          // TODO při enteru focus na první položku. Pokud je jedna rovnou vybrat.
          onItemSelected={a => {
            handleChange(a);
            dropdownRef.current?.focusInput();
            setTimeout(() => {
              setOpen(false);
              setFilterAndHighlight(false);
            });
          }}
        />
      }
    />
  );
};
