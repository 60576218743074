import * as React from "react";
import { useMemo } from "react";
import dayjs, { Dayjs } from "dayjs";
import { DateField, DateFieldProps } from "h11-client-component-lib";
import { Controller, Path, useFormContext } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";

export type FormDateFieldProps<T> = Omit<
  DateFieldProps,
  "value" | "name" | "onChange"
> & {
  field: Path<T>;
};

export function FormDateField<T extends FieldValues>({
  field,
  ...props
}: FormDateFieldProps<T>) {
  const { control } = useFormContext<T>();

  // FIXME
  //const errors = fieldError(formik, field);
  //const mandatory = fieldMandatory(formik, field);

  return (
    <Controller
      name={field}
      control={control}
      render={({ field: fieldObj }) => {
        return (
          <DateField
            name={field}
            value={fieldObj.value ? dayjs(fieldObj.value) : undefined}
            onChange={fieldObj.onChange}
            {...props}
            // FIXME
            //error={!!errors}
            //mandatory={mandatory}
            //helperText={errors?.toString() ?? props.helperText}
          />
        );
      }}
    />
  );
}
